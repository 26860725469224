<template>
  <img :src="generateURL()" :alt="alt" />
</template>

<script setup lang="ts">
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: 'icon',
  },
});

const generateURL = () => {
  const path = `/src/assets/icons/features/${props.name}.svg`;
  const modules: Record<string, { default: string }> = import.meta.glob(
    '/src/assets/icons/features/*.svg',
    { eager: true },
  );
  return modules[path]?.default;
};
</script>
