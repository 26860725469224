<template>
  <div
    class="fixed left-0 right-0 top-0 z-20 flex h-16 items-center justify-between space-x-4 border-b bg-[var(--color-bg-top-navigation)] px-4 lg:px-8"
  >
    <div class="flex min-w-0 items-center gap-1.5 md:gap-4">
      <Button class="lg:hidden" variant="ghost" size="icon" @click.stop="toggleSidebar">
        <PanelRightCloseIcon class="h-5 w-5" />
      </Button>
      <RouterLink
        :to="{ name: 'project', params: { project_uuid: 'default' } }"
        class="hidden items-center sm:flex"
      >
        <LogoGradient class="h-7 w-7" />
      </RouterLink>
      <div class="hidden text-2xl text-muted-foreground/30 sm:block">/</div>
      <div class="flex min-w-0 items-center gap-2">
        <RouterLink
          class="min-w-0 font-medium"
          :to="team ? { name: 'team', params: { uuid: team.uuid } } : { name: 'profile' }"
        >
          <div v-if="team" class="flex items-center gap-1.5 truncate">
            <span class="max-w-32 truncate">{{ team.name }}</span>
            <Badge variant="outline">Team</Badge>
          </div>
          <div v-else class="flex items-center gap-1.5">
            <span class="min-w-10 truncate"
              >{{ profile?.first_name }} {{ profile?.last_name }}</span
            >
          </div>
        </RouterLink>
        <Popover v-model:open="teamMenuOpen">
          <PopoverTrigger as-child>
            <Button
              variant="ghost"
              role="combobox"
              :aria-expanded="teamMenuOpen"
              class="px-1"
              size="sm"
            >
              <ChevronsUpDownIcon class="h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent align="start" class="w-[232px] p-0">
            <Command :filter-function="filterTeams">
              <CommandInput class="h-9" placeholder="Search team..." />
              <CommandEmpty>No teams found.</CommandEmpty>
              <CommandList>
                <CommandGroup heading="Teams">
                  <CommandItem
                    v-for="item in teams?.teams"
                    :key="item.uuid"
                    :value="item"
                    class="group flex items-center justify-between gap-4"
                    @select="
                      (ev) => {
                        // if (typeof ev.detail.value === 'string') {
                        //   // value = ev.detail.value;
                        // }
                        handleTeamSelect(item);
                        teamMenuOpen = false;
                      }
                    "
                  >
                    <div class="flex min-w-0 items-center gap-2">
                      <div
                        class="flex h-6 w-6 shrink-0 items-center justify-center rounded text-xs"
                        :style="{ backgroundColor: item.color }"
                        :class="[isColorLight(item.color) ? 'text-black' : 'text-white']"
                      >
                        {{ item.name[0]?.toUpperCase() }}
                      </div>
                      <div class="min-w-0">
                        <div class="truncate font-normal">{{ item.name }}</div>
                      </div>
                    </div>
                    <div class="flex items-center gap-1.5" v-if="currentTeamUuid === item.uuid">
                      <Check
                        :class="
                          cn(
                            'ml-auto h-4 w-4',
                            currentTeamUuid === item.uuid ? 'opacity-100' : 'opacity-0',
                          )
                        "
                      />
                      <RouterLink
                        :to="{ name: 'team', params: { uuid: item.uuid } }"
                        :class="
                          cn(
                            buttonVariants({ size: 'icon', variant: 'outline' }),
                            'hidden h-5 w-5 group-hover:inline-flex',
                          )
                        "
                      >
                        <Settings2Icon class="size-3" />
                      </RouterLink>
                    </div>
                  </CommandItem>
                </CommandGroup>
                <CommandSeparator v-if="team" />
                <CommandGroup v-if="team" heading="Personal account">
                  <CommandItem
                    class="flex items-center justify-between"
                    :value="{ name: `${profile?.first_name} ${profile?.last_name}` }"
                    @select.prevent="
                      () => {
                        handlePersonalAccountSelect();
                        teamMenuOpen = false;
                      }
                    "
                  >
                    <div class="flex items-center gap-0.5">
                      <img
                        :src="profile?.gravatar_url"
                        alt="Profile picture"
                        class="mr-2 h-6 w-6 flex-shrink-0 rounded bg-gray-300"
                      />
                      {{ profile?.first_name }} {{ profile?.last_name }}
                    </div>
                    <Check :class="cn('ml-auto h-4 w-4', !team ? 'opacity-100' : 'opacity-0')" />
                  </CommandItem>
                </CommandGroup>
                <CommandSeparator />
              </CommandList>
            </Command>
            <div class="p-1">
              <RouterLink
                class="flex items-center rounded px-2 py-1 hover:bg-muted"
                :to="{ name: 'team/create' }"
                @click="teamMenuOpen = false"
              >
                <div class="mr-2 flex h-7 w-7 items-center justify-center rounded border bg-muted">
                  <UsersIcon class="h-4 w-4" />
                </div>
                <span>Create team</span>
              </RouterLink>
            </div>
          </PopoverContent>
        </Popover>
      </div>
      <div class="text-2xl text-muted-foreground/30">/</div>
      <div class="flex min-w-0 items-center gap-2">
        <RouterLink
          class="min-w-0 font-medium"
          :to="
            currentProject
              ? { name: 'project', params: { project_uuid: currentProject.uuid } }
              : { name: 'project', params: { project_uuid: 'default' } }
          "
        >
          <div class="min-w-10 truncate">
            {{ currentProject?.name }}
          </div>
        </RouterLink>
        <Popover v-model:open="projectMenuOpen">
          <PopoverTrigger as-child>
            <Button
              variant="ghost"
              role="combobox"
              :aria-expanded="projectMenuOpen"
              class="px-1"
              size="sm"
            >
              <ChevronsUpDownIcon class="h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent align="start" class="w-[200px] p-0">
            <Command :filter-function="filterTeams">
              <CommandInput class="h-9" placeholder="Search project..." />
              <CommandEmpty>No projects found.</CommandEmpty>
              <CommandList>
                <CommandGroup heading="Projects">
                  <CommandItem
                    v-for="item in projects"
                    :key="item.uuid"
                    :value="item"
                    class="flex items-center justify-between"
                    as-child
                  >
                    <RouterLink
                      :to="{ name: 'project', params: { project_uuid: item.uuid } }"
                      @click="projectMenuOpen = false"
                    >
                      <div class="flex min-w-0 items-center gap-2">
                        <div
                          class="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded text-xs"
                          :style="{ backgroundColor: item.color }"
                          :class="[isColorLight(item.color) ? 'text-black' : 'text-white']"
                        >
                          {{ item.name[0]?.toUpperCase() }}
                        </div>
                        <div class="min-w-0">
                          <div class="truncate font-normal">{{ item.name }}</div>
                          <!-- <p class="text-muted-foreground text-xs">{{ item.members.length }} members</p> -->
                        </div>
                      </div>
                      <Check
                        :class="
                          cn(
                            'ml-auto h-4 w-4',
                            projectUuid === item.uuid ? 'opacity-100' : 'opacity-0',
                          )
                        "
                      />
                    </RouterLink>
                  </CommandItem>
                </CommandGroup>
                <CommandSeparator />
              </CommandList>
            </Command>
            <div class="p-1">
              <RouterLink
                class="flex items-center rounded px-2 py-1 hover:bg-muted"
                :to="{ name: 'project/create' }"
                @click="projectMenuOpen = false"
              >
                <div class="mr-2 flex h-7 w-7 items-center justify-center rounded border bg-muted">
                  <FolderPlusIcon class="h-4 w-4" />
                </div>
                <span>Create project</span>
              </RouterLink>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
    <div class="flex flex-shrink-0 items-center space-x-2 md:space-x-4">
      <DeployMenu />

      <Tooltip>
        <TooltipTrigger>
          <Button variant="ghost" size="icon" @click="notificationsSheetOpen = true">
            <div class="relative">
              <InboxIcon :size="20" />
              <Status
                v-if="notificationsCounter && notificationsCounter > 0"
                class="-top-1"
                status="inactive"
                size="sm"
              />
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="bottom">Notifications</TooltipContent>
      </Tooltip>

      <TopUserMenu />

      <NotificationsSheet v-model:open="notificationsSheetOpen" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Check,
  ChevronsUpDownIcon,
  FolderPlusIcon,
  InboxIcon,
  PanelRightCloseIcon,
  Settings2Icon,
  UsersIcon,
} from 'lucide-vue-next';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useAppStore } from '@/app/store';
import { useNotifications } from '@/entities/notification';
import { useProfile } from '@/entities/profile';
import { useProjects } from '@/entities/project';
import { type Team, useTeam, useTeams } from '@/entities/team';
import { useGlobalLoader } from '@/shared/hooks';
import { useTeamCookie } from '@/shared/hooks/use-team-cookie';
import { useTeamSwitch } from '@/shared/hooks/use-team-switch';
import LogoGradient from '@/shared/icons/LogoGradient.vue';
import { cn, isColorLight } from '@/shared/lib/misc';
import { Badge } from '@/shared/ui/badge';
import { Button, buttonVariants } from '@/shared/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/shared/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/ui/popover';
import Status from '@/shared/ui/status.vue';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui/tooltip';
import DeployMenu from '@/widgets/deploy-menu.vue';
import TopUserMenu from '@/widgets/top-user-menu.vue';

import NotificationsSheet from './notifications-sheet.vue';

const route = useRoute();
const { data: projects } = useProjects();
const { isSidebarOpened } = storeToRefs(useAppStore());
const { data: profile } = useProfile();
const { data: notifications } = useNotifications();
const teamMenuOpen = ref(false);
const projectMenuOpen = ref(false);
const notificationsSheetOpen = ref(false);
const { data: teams } = useTeams();
const { currentTeamUuid } = useTeamCookie();
const { data: team } = useTeam(currentTeamUuid);
const { toggle: toggleGlobalLoader } = useGlobalLoader();
const { switchToPersonalAccount, switchToTeam } = useTeamSwitch();

const projectUuid = computed(() => route.params.project_uuid as string | undefined);

const currentProject = computed(() => {
  return (
    projects.value?.find((item) => item.uuid === projectUuid.value) ??
    projects.value?.find((item) => item.is_default)
  );
});

const notificationsCounter = computed(
  () => notifications.value?.filter((n) => !n.viewed_by_user).length,
);

function filterTeams(list: unknown, searchTerm: string) {
  return (list as Team[]).filter((person) => {
    return person.name.toLowerCase().includes(searchTerm.toLowerCase());
  });
}

async function handleTeamSelect(nextTeam: Team) {
  if (nextTeam.uuid === team.value?.uuid) return;

  toggleGlobalLoader(`Switching to ${nextTeam.name} team...`);

  await switchToTeam(nextTeam);

  toggleGlobalLoader();
}

async function handlePersonalAccountSelect() {
  toggleGlobalLoader(`Switching to personal account...`);

  await switchToPersonalAccount();

  toggleGlobalLoader();
}

const toggleSidebar = () => {
  isSidebarOpened.value = !isSidebarOpened.value;
};
</script>
