import { useCookies } from '@vueuse/integrations/useCookies';
import { toRef } from 'vue';

import { TEAM_COOKIE_CONFIG } from '../config';

export function useTeamCookie() {
  const cookies = useCookies(['team'], { autoUpdateDependencies: true });
  const currentTeamUuid = toRef(() => cookies.get('team'));

  function setTeamCookie(uuid: string) {
    cookies.set('team', uuid, TEAM_COOKIE_CONFIG);
  }

  function clearTeamCookie() {
    cookies.remove('team', TEAM_COOKIE_CONFIG);
  }

  return {
    currentTeamUuid,
    setTeamCookie,
    clearTeamCookie,
  };
}
