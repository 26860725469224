import type { Ref } from 'vue';
import { computed } from 'vue';

import { IS_BILLING_ENABLED } from '@/shared/config';

import type { Profile } from '../types';

export function useProfileStatus(profile: Ref<Profile | undefined>) {
  const isUserComplete = computed(() =>
    IS_BILLING_ENABLED
      ? profile.value?.is_email_confirmed &&
        (profile.value?.is_verified || profile.value?.is_member)
      : profile.value?.is_email_confirmed,
  );
  const isConfirmed = computed(() => !!profile.value?.is_email_confirmed);
  const isVerified = computed(() => !!profile.value?.is_verified);
  const isMember = computed(() => !!profile.value?.is_member);

  return {
    isUserComplete,
    isConfirmed,
    isVerified,
    isMember,
  };
}
